import { sample } from "effector"
import { and, debounce, interval, not } from "patronum"

import { config } from "../../config"
import { assert } from "../../lib/assert"
import { AppGate } from "../app"
import { fetchSortedEventsFx, fetchvCardFx } from "../events"
import { $isConnected } from "../network"
import { showErrorToastFx } from "../toaster"
import {
	$backgroundImage,
	$bgAlignment,
	$currentEventId,
	$currentVCardId,
	$feedbackData,
	$formValues,
	$isFeedbackShown,
	$isFormExpanded,
	$isLogoShown,
	$isNearCenter,
	$isOverlayShown,
	$justCreatedvCardId,
	$preset,
	$qrCodeAlignment,
	$qrCodeImage,
	$scannedCardContent,
	$scannedImages,
	alignBgFx,
	alignQrCodeFx,
	BackgroundImageGate,
	bgAlignmentChanged,
	clearBackgroundImage,
	clearOldFeedback,
	createBackgroundImageFx,
	createNewVcardFx,
	createQrCodeFx,
	EditorScreenGate,
	eventId,
	fetchFeedbackFx,
	fetchUserDataFx,
	fillFormFromScanFx,
	fitStageIntoParentContainerFx,
	formValuesChanged,
	hapticImpactFx,
	initGesturesRecognizerFx,
	isFormExpandedChanged,
	isLogoShownChanged,
	isOverlayShownChanged,
	newVcardOnFormPressed,
	presetChanged,
	PreviewContainerGate,
	qrCodeAlignmentChanged,
	qrCodeGenTriggered,
	qrCodeImageDragEnded,
	qrCodeImageDragMoved,
	QrCodeImageGate,
	saveScannedPicturesFx,
	scanCardClicked,
	scanCardFx,
	setJustCreatedId,
	shareClicked,
	shareViaEmailClicked,
	shareViaEmailFx,
	shareWallpaperFx,
	showFeedBack,
	StageGate,
	storeContactFx,
	syncNewContactsFx,
	updateBackgroundImage,
	vCardId,
} from "./editor-units"
import { isNearCenter } from "./is-near-center"

sample({
	clock: newVcardOnFormPressed,
	target: [
		$formValues.reinit,
		$preset.reinit,
		$isLogoShown.reinit,
		qrCodeAlignmentChanged.prepend(() => "3:2"),
		bgAlignmentChanged.prepend(() => "2:2"),
	],
})
sample({
	clock: clearOldFeedback,
	target: [$feedbackData.reinit],
})
$formValues.on(formValuesChanged, (state, updates) => ({ ...state, ...updates }))

$qrCodeImage.on(createQrCodeFx.doneData, (_, image) => image)

$backgroundImage.on(createBackgroundImageFx.doneData, (_, image) => image)
$backgroundImage.on(updateBackgroundImage, (_, image) => image)
$backgroundImage.on(clearBackgroundImage, (_, image) => image)
$preset.on(presetChanged, (_, preset) => preset)

$isFormExpanded.on(isFormExpandedChanged, (_, isExpanded) => isExpanded)
$currentEventId.on(eventId, (_, id) => id)
$currentVCardId.on(vCardId, (_, id) => id)
$isFeedbackShown.on(showFeedBack, (_, isShown) => isShown)
$justCreatedvCardId.on(setJustCreatedId, (_, to) => to)
sample({
	clock: [StageGate.open, $preset.updates],
	source: $preset.map((preset) => preset.url),
	target: createBackgroundImageFx,
})

sample({
	clock: shareClicked,
	target: shareWallpaperFx,
})

sample({
	clock: [shareWallpaperFx, shareViaEmailFx],
	source: $formValues,
	target: storeContactFx,
})

sample({
	clock: qrCodeGenTriggered,
	target: createQrCodeFx,
})

sample({
	clock: [StageGate.open, PreviewContainerGate.open],
	target: fitStageIntoParentContainerFx,
})

sample({
	clock: [StageGate.open, BackgroundImageGate.open, QrCodeImageGate.open],
	target: initGesturesRecognizerFx,
})

sample({
	clock: [StageGate.open, QrCodeImageGate.open, $preset.updates],
	target: [
		// align bg to center
		alignQrCodeFx.prepend(() => "3:2"),
		alignBgFx.prepend(() => "2:2"),
	],
})

sample({
	clock: debounce({ source: qrCodeAlignmentChanged, timeout: 10 }),
	target: [alignQrCodeFx, $qrCodeAlignment],
})

sample({
	clock: debounce({ source: bgAlignmentChanged, timeout: 10 }),
	target: [alignBgFx, $bgAlignment],
})

sample({
	clock: isLogoShownChanged,
	target: $isLogoShown,
})

sample({
	clock: isOverlayShownChanged,
	target: $isOverlayShown,
})

sample({
	clock: $isLogoShown.updates,
	target: createQrCodeFx,
})

sample({
	clock: [
		createBackgroundImageFx.failData,
		createQrCodeFx.failData,
		storeContactFx.failData,
		shareWallpaperFx.failData,
	],
	target: showErrorToastFx,
})

sample({
	clock: [storeContactFx.done, $isConnected.updates, AppGate.open],
	filter: and($isConnected, not(syncNewContactsFx.pending)),
	target: syncNewContactsFx,
})

sample({
	clock: interval({ timeout: config.trySyncEvery, start: AppGate.open }).tick,
	filter: and($isConnected, not(syncNewContactsFx.pending)),
	target: syncNewContactsFx,
})

sample({
	clock: qrCodeImageDragMoved,
	source: { stageRef: StageGate.state, qrCodeImageRef: QrCodeImageGate.state },
	fn: ({ stageRef, qrCodeImageRef }) => {
		const stage = stageRef.current
		assert(stage, "stage is not initialized")

		const qrCodeImage = qrCodeImageRef.current
		assert(qrCodeImage, "qrCodeImage is not initialized")

		return isNearCenter(stage, qrCodeImage)
	},
	target: $isNearCenter,
})

sample({
	clock: $isNearCenter.updates,
	filter: (isNearCenter) => isNearCenter,
	target: hapticImpactFx,
})

sample({
	clock: qrCodeImageDragEnded,
	source: $isNearCenter,
	filter: Boolean,
	target: qrCodeAlignmentChanged.prepend(() => "3:2"),
})

sample({
	clock: fetchUserDataFx.doneData,
	target: $formValues,
})

sample({
	clock: fetchFeedbackFx.doneData,
	target: $feedbackData,
})
sample({
	clock: createNewVcardFx.done,
	source: $currentEventId,
	target: [fetchSortedEventsFx, fetchvCardFx],
})

sample({
	clock: scanCardClicked,
	target: scanCardFx,
})

sample({
	clock: [scanCardFx.failData, saveScannedPicturesFx.failData],
	target: showErrorToastFx,
})

sample({
	clock: scanCardFx.doneData,
	target: fillFormFromScanFx,
})

sample({
	clock: fillFormFromScanFx.doneData,
	target: $formValues,
})

sample({
	clock: scanCardFx.doneData,
	fn: (data) => data.images,
	target: $scannedImages,
})

sample({
	clock: scanCardFx.doneData,
	fn: (data) => data.content,
	target: $scannedCardContent,
})

sample({
	clock: scanCardFx.doneData,
	target: saveScannedPicturesFx,
})

$scannedCardContent.reset(EditorScreenGate.close)
$scannedImages.reset(EditorScreenGate.close)

sample({
	clock: shareViaEmailClicked,
	target: shareViaEmailFx,
})

sample({
	clock: shareViaEmailFx.failData,
	target: showErrorToastFx,
})

sample({
	clock: scanCardClicked,
	target: scanCardFx,
})

sample({
	clock: [scanCardFx.failData, saveScannedPicturesFx.failData],
	target: showErrorToastFx,
})

sample({
	clock: scanCardFx.doneData,
	target: fillFormFromScanFx,
})

sample({
	clock: fillFormFromScanFx.doneData,
	target: $formValues,
})

sample({
	clock: scanCardFx.doneData,
	fn: (data) => data.images,
	target: $scannedImages,
})

sample({
	clock: scanCardFx.doneData,
	fn: (data) => data.content,
	target: $scannedCardContent,
})

sample({
	clock: scanCardFx.doneData,
	target: saveScannedPicturesFx,
})

$scannedCardContent.reset(EditorScreenGate.close)
$scannedImages.reset(EditorScreenGate.close)

sample({
	clock: shareViaEmailClicked,
	target: shareViaEmailFx,
})

sample({
	clock: shareViaEmailFx.failData,
	target: showErrorToastFx,
})
