/* eslint-disable effector/no-getState */
import { sample } from "effector"

import { $currentEventId } from "../editor"
import { showErrorToastFx } from "../toaster"
import {
	$chronoOrAlpa,
	$chronoOrAlpaVcards,
	$descOrAsc,
	$events,
	$fieldUpdate,
	$isEventChanged,
	$isFormExpanded,
	$isShareButtonVisible,
	$isUpdate,
	$vCards,
	chronoOrAlpaVcards,
	chronoOrAlpha,
	deleteEventsFx,
	deletevCardsFx,
	descOrAsc,
	EventsScreenGate,
	fetchSortedEventsFx,
	fetchSortedVcardsFx,
	fetchvCardFx,
	isEventIdChanged,
	isFieldUpdated,
	isFormExpandedChanged,
	isUpdateAvailable,
	SendCardFx,
	shareButtonDisplay,
	statusCardFx,
	updateOnShare,
} from "./events-units"

$isFormExpanded.on(isFormExpandedChanged, (_, isExpanded) => isExpanded)

$isEventChanged.on(isEventIdChanged, (_, eventIdWasChanged) => eventIdWasChanged)
$isUpdate.on(isUpdateAvailable, (state) => !state).on(updateOnShare, (_, payload) => payload)
$isShareButtonVisible.on(shareButtonDisplay, (_, payload) => payload)

// eslint-disable-next-line effector/no-duplicate-on
$fieldUpdate.on(isFieldUpdated, (state) => !state).on(isFieldUpdated, (_, payload) => payload)

$chronoOrAlpa.on(chronoOrAlpha, (_, chronoOrAlphaWasChanged) => chronoOrAlphaWasChanged)
$descOrAsc.on(descOrAsc, (_, descOrAscWasChandeg) => descOrAscWasChandeg)

$chronoOrAlpaVcards.on(chronoOrAlpaVcards, (_, chronoOrAlphaWasChanged) => chronoOrAlphaWasChanged)

sample({
	clock: fetchSortedEventsFx.doneData,
	target: $events,
})
sample({
	clock: fetchvCardFx.doneData,
	target: $vCards,
})

sample({
	clock: fetchSortedVcardsFx.doneData,
	target: $vCards,
})
sample({
	clock: EventsScreenGate.open,
	target: fetchSortedEventsFx,
})
SendCardFx.done.watch(() => {
	fetchSortedEventsFx()
})

sample({
	clock: [fetchvCardFx.failData, SendCardFx.failData, fetchSortedEventsFx.failData],
	target: showErrorToastFx,
})

deletevCardsFx.done.watch(() => {
	fetchvCardFx($currentEventId.getState())
})
deleteEventsFx.done.watch(() => {
	fetchSortedEventsFx()
})
statusCardFx.done.watch(() => {
	fetchvCardFx($currentEventId.getState())
})
