import { useEffect, useRef, useState } from "react"
import React from "react"
import { useIntl } from "react-intl"
import {
	IonButton,
	IonCheckbox,
	IonInput,
	IonItem,
	IonList,
	IonModal,
	IonRadio,
	IonRadioGroup,
	IonTextarea,
} from "@ionic/react"
import { useKeyboardState } from '@ionic/react-hooks/keyboard'
import { useUnit } from "effector-react"
import clsx from "clsx"

import {
	$currentVCardId,
	$feedbackData,
	$isFormValid,
	$justCreatedvCardId,
	clearOldFeedback,
	createFeedbackFx,
	fetchFeedbackFx,
	shareClicked,
	updateFeedbackFx,
} from "../store/editor"
import { $isUpdate, isFieldUpdated, isUpdateAvailable } from "../store/events"
import { $isShareButtonVisible, shareButtonDisplay } from "../store/events/events-units"
import classes from "./feedback-modal.module.css"
import SaveModal from "./save-modal"


export function FeedbackModal() {
	const intl = useIntl()
	const currentVCardId = useUnit($currentVCardId)

	const feedbackData = useUnit($feedbackData)
	const [showAlert, setShowAlert] = useState(false)
	const [saveAlert, setSaveAlert] = useState(false)
	const justCreatedId = useUnit($justCreatedvCardId)

	const [additionalData, setAdditionalData] = useState(feedbackData.additionalData)
	const [accountManager, setAccountManager] = useState(feedbackData.accountManager)
	const [topics, setTopic] = useState<string[]>(feedbackData.topics)
	const [reconnectionAmount, setReconnectionAmount] = useState(feedbackData.reconnectionAmount)
	const [reconnectionPeriod, setReconnectionPeriod] = useState<'DAY' | 'WEEK' | 'MONTH' | ''>(feedbackData.reconnectionPeriod)
	const { isOpen } = useKeyboardState()
	const daysInMonth = Array.from({ length: 30 }, (_, index) => index + 1)
	const scrollRef = useRef<HTMLDivElement>(null)

	const isShareVisible = useUnit($isShareButtonVisible)

	const { isFormValid } = useUnit({
		isFormValid: $isFormValid,
	})

	useEffect(() => {
		setAdditionalData(feedbackData.additionalData)
		setAccountManager(feedbackData.accountManager)
		setTopic(feedbackData.topics)
		setReconnectionAmount(feedbackData.reconnectionAmount || 2)
		setReconnectionPeriod(feedbackData.reconnectionPeriod || 'WEEK')
	}, [feedbackData])

	useEffect(() => {
		const timer = setTimeout(() => {
			if (scrollRef.current) {
				const listItemHeight = 45
				const visibleItems = 3
				const totalItems = daysInMonth.length
				const maxScrollPosition = (totalItems - visibleItems) * listItemHeight
				if (!feedbackData.reconnectionAmount) {
					scrollRef.current.scrollTop = 45
				} else if (feedbackData.reconnectionAmount > 10) {
					scrollRef.current.scrollTop = feedbackData.reconnectionAmount * (maxScrollPosition / totalItems)
				} else {
					scrollRef.current.scrollTop = (feedbackData.reconnectionAmount - 1) * listItemHeight
				}
			}
		}, 10)

		return () => {
			clearTimeout(timer)
		}
	  }, [daysInMonth.length, feedbackData, showAlert])

	const handleCheckboxChange = (event: CustomEvent) => {
		const { value, checked } = event.detail
		if (checked) {
			setTopic((prevCheckedItems) => [...prevCheckedItems, value])
		} else {
			setTopic((prevCheckedItems) => prevCheckedItems.filter((item) => item !== value))
		}
	}

	const createFeedback = () => {
		if (feedbackData.id === 0) {
			createFeedbackFx({
				additionalData,
				topics,
				accountManager,
				reconnectionPeriod: reconnectionPeriod || 'DAY',
				reconnectionAmount,
			})
		} else {
			updateFeedbackFx({
				additionalData,
				topics,
				accountManager,
				id: feedbackData.id,
				reconnectionPeriod: reconnectionPeriod || 'DAY',
				reconnectionAmount,
			})
		}
		clearOldFeedback()
		setShowAlert(false)
	}
	const feedBackFetchonClick = () => {
		if (justCreatedId !== 0) {
			fetchFeedbackFx(justCreatedId)
		} else {
			fetchFeedbackFx(currentVCardId)
		}
		setShowAlert(true)
	}
	const ClearandCloseModal = () => {
			clearOldFeedback()
			setShowAlert(false)
	}

	const handleRadioButtonsChange = (event: CustomEvent) => {
		setReconnectionPeriod(event.detail.value)
	}

	const handleScroll = () => {

		if (scrollRef.current) {
			const scrollTop = scrollRef.current.scrollTop
			const listItemHeight = 45
			const visibleItems = 3
			const totalItems = daysInMonth.length
			const maxScrollPosition = (totalItems - visibleItems) * listItemHeight
			const scrollIndex = scrollTop === 0 ? 1 : Math.ceil(scrollTop / (maxScrollPosition / totalItems))

			setReconnectionAmount(scrollIndex)
		}
	}

	const shareClickedBtn = () => {
		shareButtonDisplay(false)
		shareClicked()
		isUpdateAvailable()
		if (feedbackData.id === 0) {
			clearOldFeedback()
		}
	}


	const isUpdate = useUnit($isUpdate)

	return (
		<div>
			{!isShareVisible && (
				<React.Fragment>
					<IonButton
						shape="round"
						onClick={() => feedBackFetchonClick()}
						expand="full"
					>
						{intl.formatMessage({
							id: "editor-lead-form-share",
							defaultMessage: "Lead Qualification",
						})}
					</IonButton>

					<IonButton
						shape="round"
						onClick={() => {
							setSaveAlert(true)
							isFieldUpdated(false)
							shareButtonDisplay(false)
						}}
						expand="full"
						disabled={!isFormValid}
					>
						{intl.formatMessage({
							id: "save-lead-form-share",
							defaultMessage: "Save changes",
						})}
					</IonButton>
				</React.Fragment>
				)
			}

			{isUpdate && currentVCardId === 0 && isShareVisible && (
					<IonButton
						shape="round"
						onClick={() => shareClickedBtn()}
						disabled={!isFormValid}
						expand="full"
					>
						{intl.formatMessage({
							id: "editor-form-share",
							defaultMessage: "Share vCard",
						})}
					</IonButton>
				)}

			<SaveModal saveAlert={saveAlert} setSaveAlert={setSaveAlert} />

			<IonModal
				isOpen={showAlert}
				className={isOpen ? classes.newEventModalKeyboardOpen : classes.newEventModal}
				onDidDismiss={() => ClearandCloseModal()}
			>
				<div className={classes.modalContent}>
					<div className={classes.modalWrapper}>
						<div className={classes.feedbackModalLeft}>
							<h4 className={classes.feedbackModalTittle}>
								{intl.formatMessage({
									id: "feedback-choose-the-topic-of-interest",
									defaultMessage: "Choose the Topics of Interest",
								})}
							</h4>
							<div className={classes.topic}>
								<IonList className={classes.topicCheckboxList}>
									<IonCheckbox
										className={classes.topicCheckbox}
										justify="space-between"
										value="ATTRACTING_AUDIENCE"
										checked={topics.includes("ATTRACTING_AUDIENCE")}
										onIonChange={handleCheckboxChange}
									>
										{intl.formatMessage({
											id: "staff-augmentation",
											defaultMessage: "Staff Augmentation",
										})}
									</IonCheckbox>

									<IonCheckbox
										className={classes.topicCheckbox}
										justify="space-between"
										value="FINDING_EMPLOYEES"
										checked={topics.includes("FINDING_EMPLOYEES")}
										onIonChange={handleCheckboxChange}
									>
										{intl.formatMessage({
											id: "application-or-system-development",
											defaultMessage: "Application or System Development ",
										})}
									</IonCheckbox>

									<IonCheckbox
										className={clsx(classes.topicCheckbox, classes.lastElement)}
										justify="space-between"
										value="FINDING_PARTNERS"
										checked={topics.includes("FINDING_PARTNERS")}
										onIonChange={handleCheckboxChange}
									>
										{intl.formatMessage({
											id: "development-partner",
											defaultMessage: "Development Partner",
										})}
									</IonCheckbox>
								</IonList>
							</div>
							<div>
								<h4 className={classes.feedbackModalTittle}>
									{intl.formatMessage({
										id: "when-to-follow-up",
										defaultMessage: "When to Follow Up",
									})}
								</h4>
								<div className={classes.dateContainer}>
									<IonList>
										<IonRadioGroup className={classes.radioGroup} value={reconnectionPeriod} onIonChange={handleRadioButtonsChange}>
											<IonItem className={classes.radioItem} lines="none">
												{intl.formatMessage({
													id: "radio-button-day",
													defaultMessage: "Day",
												})}
												<IonRadio className={classes.radioBox} color="light" slot="end" value="DAY" style={ reconnectionPeriod === 'DAY' ? { background: '#017793' } : undefined }/>
											</IonItem>
											<IonItem className={classes.radioItem} lines="none">
												{intl.formatMessage({
													id: "radio-button-week",
													defaultMessage: "Week",
												})}
												<IonRadio className={classes.radioBox} color="light" slot="end" value="WEEK" style={ reconnectionPeriod === 'WEEK' ? { background: '#017793' } : undefined} />
											</IonItem>
											<IonItem className={classes.radioItem} lines="none">
												{intl.formatMessage({
													id: "radio-button-month",
													defaultMessage: "Month",
												})}
												<IonRadio className={classes.radioBox} color="light" slot="end" value="MONTH" style={ reconnectionPeriod === 'MONTH' ? { background: '#017793' } : undefined} />
											</IonItem>
										</IonRadioGroup>
									</IonList>
									<div className={classes.numbersWheel} ref={scrollRef} onScroll={handleScroll}>
										<ul className={classes.numbersWheelList}>
											{daysInMonth.map((number) => (
												<li
													style={ number === reconnectionAmount ? {
														backgroundColor: "rgba(116, 116, 128, 0.08)",
														color: 'black',
														borderRadius: '7px'
													}: undefined }
													id={`number-${number}`}
													className={classes.numbersWheelListItem} key={number}
												>
													{number}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>

							<div>
								<div className={classes.responsible}>
									<IonInput
										className={classes.responsibleInput}
										name="person"
										value={accountManager}
										label={intl.formatMessage({
											id: "account-manager",
											defaultMessage: "Account Manager",
										})}
										onIonInput={(e) => {
											const newValue = String(e.detail.value || "")
											setAccountManager(newValue)
										}}
										labelPlacement="floating"
										type="text"
									/>
								</div>
							</div>
						</div>
						<div className={classes.feedbackModalRight}>
							<div className={classes.commentBox}>
								<IonTextarea
									className={classes.commentInput}
									name="comment"
									label={intl.formatMessage({
										id: "feedback-additional-information",
										defaultMessage: "Additional Information",
									})}
									value={additionalData}
									labelPlacement="floating"
									onIonInput={(e) => {
										const newValue = String(e.detail.value || "")
										setAdditionalData(newValue)
									}}
								/>
							</div>
						</div>
					</div>
					<div className={classes.feedbackButtonBox}>
						<IonButton
							className={classes.feedbackButton}
							shape="round"
							expand="full"
							onClick={() => createFeedback()}
						>
							{intl.formatMessage({
								id: "feedback-modal-apply",
								defaultMessage: "Apply",
							})}
						</IonButton>
						<IonButton
							shape="round"
							expand="full"
							className={classes.feedbackButtonCancel}
							fill="clear"
							onClick={() => ClearandCloseModal()}
						>
							{intl.formatMessage({
								id: "feedback-modal-cancel",
								defaultMessage: "Cancel",
							})}
						</IonButton>
					</div>
				</div>
			</IonModal>
		</div>
	)
}
