/* eslint-disable effector/no-getState */
import { useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonCheckbox, IonItem, IonList, IonToggle } from "@ionic/react"
import { useUnit } from "effector-react"
import clsx from "clsx"
import fileSave from "file-saver"

import { screensUrlFactory } from "../app/screens"
import { config } from "../config"
import SortComponent from "../sort-component/sort-component"
import {
	$currentEventId,
	clearBackgroundImage,
	clearOldFeedback,
	fetchUserDataFx,
	newVcardOnFormPressed,
	presetChanged,
	setJustCreatedId,
	showFeedBack,
	vCardId } from "../store/editor"
import {
	$events,
	$isEventChanged,
	$vCards,
	deletevCardsFx,
	downloadAllCardFx,
	downloadSpecificCardFx,
	fetchSortedVcardsFx,
	isEventIdChanged,
	isFieldUpdated,
	statusCardFx,
	updateOnShare,
} from "../store/events"
import { shareButtonDisplay } from "../store/events/events-units"
import { DeleteVcard } from "./delete-vcard"
import { DonwloadIcon } from "./download-icon"
import { LockIcon } from "./lock-icon"
import { ShareIcon } from "./share-icon"
import { UnlockIcon } from "./unlock-icon"
import classes from "./vcard-section.module.css"

export function VcardSection() {
	const intl = useIntl()
	const vCards = useUnit($vCards)
	const eventsData = useUnit($events)
	const isEventChanged = useUnit($isEventChanged)
	const currentEventId = useUnit($currentEventId)
	const [selectedVcards, setSelectedVcards] = useState<number[]>([])
	const isAllVcardChecked = selectedVcards.length === vCards?.owner?.length

	/// / can be implemented in a better way. quick soluiton JUST FOR DEMO.
	const currentEventName = eventsData
		.map((eventYear) => {
			return eventYear.events.filter((event) => {
				return event.id === $currentEventId.getState()
			})
		})
		.filter((e) => e.length > 0)

	const eventStatusHandler = () => {
		if (!currentEventId) return
		statusCardFx({ eventId: currentEventId, active: !vCards?.active })
	}

	// select one element
	const handleCheckboxChange = (vCardId: number) => {
		isEventChanged && setSelectedVcards([])
		setSelectedVcards((prevSelectedVcards) => {
			if (prevSelectedVcards.includes(vCardId)) {
				// If the vCard is already selected, remove it from the array
				return prevSelectedVcards.filter((id) => id !== vCardId)
			} else {
				// If the vCard is not selected, add it to the array
				return [...prevSelectedVcards, vCardId]
			}
		})
		isEventIdChanged(false)
	}
	// select all element
	const handleSelectAllToggle = () => {
		isEventChanged && setSelectedVcards([])
		if (isAllVcardChecked) {
			setSelectedVcards([])
		} else {
			const allVcardIds = vCards?.owner?.map((item) => item.vcardId) ?? []
			setSelectedVcards(allVcardIds)
		}
		isEventIdChanged(false)
	}

	const handleDownloadAllVcards = async (isShare: boolean) => {
		if (selectedVcards.length > 0) {
			// TODO: move out of component
			const downloadedContent = await downloadAllCardFx($currentEventId.getState())

			const fileName = `${currentEventName[0][0].title}.csv`
			const blob = new Blob([downloadedContent])
			const file = new File([blob], fileName, { type: "image/png" })

			if ("share" in navigator && isShare) {
				return await navigator.share({ title: fileName, files: [file] })
			}

			fileSave(file, fileName)
		}
	}
	const selectedVcardsByComma = selectedVcards.join(",")

	const handleDownload = async (isShare: boolean) => {
		if (selectedVcards.length > 0) {
			// TODO: move out of component
			const downloadedContent = await downloadSpecificCardFx({
				eventId: $currentEventId.getState(),
				vCardsId: selectedVcardsByComma,
			})

			const fileName = `${currentEventName[0][0].title}.csv`
			const blob = new Blob([downloadedContent])
			const file = new File([blob], fileName, { type: "image/png" })

			if ("share" in navigator && isShare) {
				return await navigator.share({ title: fileName, files: [file] })
			}

			fileSave(file, fileName)
		}
	}
	const handleDeleteCards = () => {
		if (selectedVcards.length === 0) {
			return
		}
		deletevCardsFx(selectedVcardsByComma)
		setSelectedVcards([])
	}

	const createNewVcard = () => {
		newVcardOnFormPressed()
		vCardId(0)
		showFeedBack(false)
		updateOnShare(true)
	}
	const fetchUserData = (vCardsId: number) => {
		clearOldFeedback()
		fetchUserDataFx(vCardsId)
		shareButtonDisplay(false)
		vCardId(vCardsId)
		updateOnShare(false)
		isFieldUpdated(false)
	}

	return (
		<div className={classes.root}>
			<div className={classes.headingSticky}>
				<div className={classes.headingBox}>
					<h2 className={classes.headingBoxTitle}>{vCards.title}</h2>
					<div className={classes.actionBox}>
						<DeleteVcard
							disabled={selectedVcards.length === 0}
							handleDeleteVcards={() => handleDeleteCards()}
						/>
						{!vCards?.active ? (
							<IonButton fill="clear" onClick={eventStatusHandler}>
								<LockIcon />
							</IonButton>
						) : (
							<IonButton fill="clear" onClick={eventStatusHandler}>
								<UnlockIcon />
							</IonButton>
						)}
						<SortComponent handleSort={() => fetchSortedVcardsFx()} />
						{config.isWeb && (
							<IonButton
								fill="clear"
								onClick={() =>
									isAllVcardChecked
										? handleDownloadAllVcards(false)
										: handleDownload(false)
								}
							>
								<DonwloadIcon />
							</IonButton>
						)}
						<IonButton
							fill="clear"
							onClick={() =>
								isAllVcardChecked
									? handleDownloadAllVcards(true)
									: handleDownload(true)
							}
						>
							<ShareIcon />
						</IonButton>
					</div>
				</div>
				<IonToggle
					className={classes.selectAll}
					checked={isAllVcardChecked && selectedVcards.length !== 0}
					onClick={() => handleSelectAllToggle()}
				>
					{intl.formatMessage({
						id: "events-vcard-select-all",
						defaultMessage: "Select all",
					})}
				</IonToggle>
			</div>
			<IonList className={classes.eventList}>
				{vCards?.owner?.map((item) => (
					<IonItem key={item.vcardId} lines="none" className={classes.ionItem}>
						<div className={classes.cardBox}>
							<div className={classes.vcardCheckboxContainer}>
								<IonCheckbox
									className={classes.vcardCheckbox}
									checked={selectedVcards.includes(item.vcardId)}
									onIonChange={() => handleCheckboxChange(item.vcardId)}
								/>
								<IonButton
									fill="clear"
									routerLink={screensUrlFactory.editor(item.vcardId)}
									className={classes.vcardCheckboxButton}
									onClick={() => fetchUserData(item.vcardId)}
								>
									<div className={classes.vcardCheckboxBox}>
										{item.name}
										<span
											className={clsx(
												classes.vcardCheckboxDescription,
												item.company === "" &&
													item.position === "" &&
													classes.vcardDescriptionEmpty
											)}
										>
											{item.company}
											<div className={classes.roundedDot} />
											{item.position}
										</span>
									</div>
								</IonButton>
							</div>
						</div>
					</IonItem>
				))}
			</IonList>
			<div>
				<IonButton
					routerLink={screensUrlFactory.editor()}
					shape="round"
					disabled={!currentEventId || !vCards?.active}
					className={classes.newVcard}
					onClick={() => {
						clearBackgroundImage(null)
						presetChanged({ url: "" })
						createNewVcard()
						shareButtonDisplay(true)
						setJustCreatedId(0)
						}
					}
				>
					{intl.formatMessage({
						id: "events-new-vCard-button",
						defaultMessage: "New vCard",
					})}
				</IonButton>
			</div>
		</div>
	)
}
