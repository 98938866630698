export const DonwloadIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
		<path
			stroke="#54687D"
			strokeMiterlimit={10}
			strokeWidth={2}
			d="M21 14v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-5M11 0v16"
		/>
		<path stroke="#54687D" strokeLinejoin="round" strokeWidth={2} d="m5 10 6 6 6-6" />
	</svg>
)
