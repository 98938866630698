import { useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonModal } from "@ionic/react"
import { useUnit } from "effector-react"
import clsx from "clsx"

import { config } from "../config"
import { $preset, $presets, clearBackgroundImage, presetChanged } from "../store/editor"
import { updateBackgroundImage } from "../store/editor/editor-units"
import { ExpandGaleryIcon } from "./expand-galery-icon"
import classes from "./expanded-gallery-modal.module.css"

export function ExpandedGalleryModal() {
	const intl = useIntl()
	const [showAlert, setShowAlert] = useState(false)
	const list = useUnit($presets)
	const backgroundImage = useUnit($preset)

	const clearBgImage = () => {
		clearBackgroundImage(null)
		presetChanged({ url: "" })
		setShowAlert(false)
	}
	return (
		<div className={classes.root}>
			<IonButton onClick={() => setShowAlert(true)} fill="clear" expand="full">
				<ExpandGaleryIcon />
			</IonButton>
			<IonModal
				isOpen={showAlert}
				className={classes.galleryModal}
				onDidDismiss={() => setShowAlert(false)}
			>
				<div className={classes.modalContent}>
					<div className={classes.galleryContent}>
						<div className={classes.galleryContentLeft}>
							<div
								className={clsx(
									classes.imageLeft,
									backgroundImage.url === "" ? classes.imageLeftEmpty : ""
								)}
								style={{ backgroundImage: `url(${backgroundImage.url}` }}
							/>
						</div>
						<div className={classes.galleryContentRight}>
							{list
								.filter((preset) => preset.url !== "")
								.map((preset) => (
									<div
										key={preset.url}
										className={classes.imagesRight}
										style={{ backgroundImage: `url(${preset.url}` }}
										onClick={() => {
											const newImage = new Image()
											newImage.src = preset.url
											newImage.width = config.canvas.width
											newImage.height = config.canvas.height
											presetChanged(preset)
											updateBackgroundImage(newImage)
										}
										}
									/>
								))}
						</div>
					</div>
					<div className={classes.galleryButtonBox}>
						<IonButton
							className={classes.galleryButton}
							shape="round"
							expand="full"
							onClick={() => setShowAlert(false)}
						>
							{intl.formatMessage({
								id: "gallery-modal-apply",
								defaultMessage: "Apply",
							})}
						</IonButton>
						<IonButton
							shape="round"
							expand="full"
							className={classes.galleryButtonCancel}
							fill="clear"
							onClick={clearBgImage}
						>
							{intl.formatMessage({
								id: "gallery-modal-cancel",
								defaultMessage: "Cancel",
							})}
						</IonButton>
					</div>
				</div>
			</IonModal>
		</div>
	)
}
