import { SetStateAction, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonPopover, IonRadio, IonRadioGroup } from "@ionic/react"
import clsx from "clsx"

import { chronoOrAlpaVcards, chronoOrAlpha, descOrAsc } from "../store/events"
import { FilterIcon } from "./filter-icon"
import classes from "./sort-component.module.css"
interface Prop {
	// waiting for sorting vcards too
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSort?: any
}
export default function SortComponent({ handleSort }: Prop) {
	const intl = useIntl()
	const popover = useRef<HTMLIonPopoverElement>(null)
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const openPopover = (e: any) => {
		if (popover.current) {
			popover.current.event = e
			setIsPopoverOpen(true)
		}
	}
	const [selectedValue, setSelectedValue] = useState("newest")
	const handleRadioChange = (event: { detail: { value: SetStateAction<string> } }) => {
		const newValue = event.detail.value
		setSelectedValue(newValue)
		if (newValue === "newest" || newValue === "z-a") {
			descOrAsc("desc")
		} else {
			descOrAsc("asc")
		}
		if (newValue === "newest" || newValue === "oldest") {
			chronoOrAlpha("id")
			chronoOrAlpaVcards("id")
		} else {
			chronoOrAlpha("title")
			chronoOrAlpaVcards("name")
		}
		handleSort?.()
	}
	return (
		<div>
			<IonButton onClick={openPopover} fill="clear">
				<FilterIcon />
			</IonButton>
			<IonPopover
				ref={popover}
				isOpen={isPopoverOpen}
				className={classes.eventsPopover}
				onDidDismiss={() => setIsPopoverOpen(false)}
			>
				<IonRadioGroup value={selectedValue} onIonChange={handleRadioChange}>
					<div className={classes.sort}>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="newest"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									selectedValue === "newest" && classes.radioButtonChecked
								)}
								aria-checked
							>
								{intl.formatMessage({
									id: "sorting-newest",
									defaultMessage: "Newest",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="oldest"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									selectedValue === "oldest" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-oldest",
									defaultMessage: "Oldest",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="a-z"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									selectedValue === "a-z" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-a-z",
									defaultMessage: "A-Z",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="z-a"
								className={clsx(
									classes.sortCheckboxSecond,
									classes.radioButton,
									selectedValue === "z-a" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-z-a",
									defaultMessage: "Z-A",
								})}
							</IonRadio>
						</div>
					</div>
				</IonRadioGroup>
			</IonPopover>
		</div>
	)
}
