import { IonButton, IonModal } from '@ionic/react'
import { useUnit } from 'effector-react'
import clsx from 'clsx'

import { intl } from '../i18n'
import { $formValues } from '../store/editor'
import { $currentEventId, updateVcardFx } from "../store/editor/editor-units"
import { fetchvCardFx, updateOnShare } from "../store/events"
import modalClasses from "./../login/sign-out-button.module.css"

// eslint-disable-next-line react/prop-types, @typescript-eslint/no-explicit-any
const SaveModal = ( { saveAlert, setSaveAlert }: any ) => {

    const { values, currentEventId } = useUnit({
		values: $formValues,
		currentEventId: $currentEventId
	})
  return (
    <IonModal
				isOpen={saveAlert}
				className={modalClasses.signOutModal}
				onDidDismiss={() => setSaveAlert(false)}
    >
				<div className={modalClasses.modalWrapper}>
					<p className={modalClasses.saveModalTitle}>
						{intl.formatMessage({
							id: "do-you-want-save-changes",
							defaultMessage: "Are you sure you want to save your changes?",
						})}
					</p>
					<div className={modalClasses.modalsButtons}>
						<div className={clsx(modalClasses.modalButtonWrapper, modalClasses.modalButtonFirst)}>
							<IonButton
								className={modalClasses.modalButton}
								fill="clear"
								onClick={() => setSaveAlert(false)}
							>
								{intl.formatMessage({
									id: "cancel",
									defaultMessage: "Cancel",
								})}
							</IonButton>
						</div>
						<div className={modalClasses.modalButtonWrapper}>
							<IonButton
								className={modalClasses.modalButton}
								fill="clear"
									onClick={async () => {
										await updateVcardFx(values)
										fetchvCardFx(currentEventId)
										setSaveAlert(false)
										updateOnShare(false)
									}
								}
							>
								{intl.formatMessage({
									id: "ok",
									defaultMessage: "OK",
								})}
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
  )
}

export default SaveModal
