import React, { Fragment, useEffect, useRef, useState } from "react"
import { Image, Layer, Stage, Text, Transformer } from "react-konva"
import { useGate, useUnit } from "effector-react"
import clsx from "clsx"
import Konva from "konva"

import { config } from "../config"
import {
	$backgroundImage,
	$isNearCenter,
	$isOverlayShown,
	$qrCodeImage,
	BackgroundImageGate,
	PreviewContainerGate,
	qrCodeImageDragEnded,
	qrCodeImageDragMoved,
	QrCodeImageGate,
	StageGate,
} from "../store/editor"
import { $isUpdate } from "../store/events"
import classes from "./preview-section.module.css"

export function PreviewSection() {
	const containerRef = useRef<HTMLDivElement>(null)
	const stageRef = useRef<Konva.Stage>(null)
	const bgRef = useRef<Konva.Image>(null)
	const qrRef = useRef<Konva.Image>(null)
	const transformerRef = useRef<Konva.Transformer>(null)

	useGate(PreviewContainerGate, containerRef)
	useGate(StageGate, stageRef)
	useGate(BackgroundImageGate, bgRef)
	useGate(QrCodeImageGate, qrRef)

	const [isDragging, setIsDragging] = useState(false)

	const { bgImage, isOverlayShown, qrImage, isNearCenter, isUpdate } = useUnit({
		bgImage: $backgroundImage,
		qrImage: $qrCodeImage,
		isOverlayShown: $isOverlayShown,
		isNearCenter: $isNearCenter,
		isUpdate: $isUpdate,
	})
	useEffect(() => {
		if (transformerRef.current && qrRef.current) {
			transformerRef.current.nodes([qrRef.current])
			const layer = transformerRef.current.getLayer()
			if (layer) {
				layer.batchDraw()
			}
		}
	}, [bgImage, isUpdate])

	return (
		<div
			ref={containerRef}
			className={clsx(classes.container, !bgImage ? classes.imageNotSelected : null)}
		>
			<Stage ref={stageRef} width={config.canvas.width} height={config.canvas.height}>
				{bgImage && (
					<Layer>
						<Image id="background" ref={bgRef} image={bgImage} opacity={0} draggable />
					</Layer>
				)}

				{qrImage && (
					<Layer>
						{bgImage ? (
							<React.Fragment>
								<Image
									id="qr"
									ref={qrRef}
									image={qrImage}
									cornerRadius={20}
									draggable
									width={config.qrCode.size}
									height={config.qrCode.size}
									onDragStart={() => setIsDragging(true)}
									onDragMove={() => qrCodeImageDragMoved()}
									onDragEnd={() => {
										setIsDragging(false)
										qrCodeImageDragEnded()
									}}
								/>
								{isUpdate && config.isWeb && (
									<Transformer
										ref={transformerRef}
										anchorSize={30}
										rotateEnabled={false}
										enabledAnchors={[
											"top-left",
											"top-right",
											"bottom-left",
											"bottom-right",
										]}
										boundBoxFunc={(oldBox, newBox) => {
											if (
												newBox.width < 500 ||
												newBox.height < 500 ||
												newBox.width > 1000 ||
												newBox.height > 1000
											)
												return oldBox
											return newBox
										}}
									/>
								)}
							</React.Fragment>
						) : (
							<Text
								text="Select a photo from the gallery or take a photo"
								x={400}
								y={config.canvas.height / 2}
								fill="#95A5B6"
								width={400}
								height={300}
								fontSize={50}
								lineHeight={1.5}
							/>
						)}
					</Layer>
				)}
			</Stage>

			{isOverlayShown && (
				<img
					className={classes.overlay}
					src="/ios-native-elements-overlay.png"
					alt="ios native elements overlay"
				/>
			)}

			{isNearCenter && isDragging && (
				<Fragment>
					<div className={classes.centerLineVertical} />
					<div className={classes.centerLineHorizontal} />
				</Fragment>
			)}
		</div>
	)
}
