import { useIntl } from "react-intl"
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonTitle,
	IonToolbar,
	RefresherEventDetail,
} from "@ionic/react"
import { useGate, useUnit } from "effector-react"
import clsx from "clsx"

import { ExpandButton } from "../expand-button/expand-button"
import { SignOutButton } from "../login"
import { $currentEventId } from "../store/editor"
import {
	$isFormExpanded,
	EventsScreenGate,
	fetchSortedEventsFx,
	fetchvCardFx,
	isFormExpandedChanged,
} from "../store/events"
import classes from "./events-screen.module.css"
import { EventsSection } from "./events-section"
import { VcardSection } from "./vcard-section"

export function EventsScreen() {
	useGate(EventsScreenGate)
	const intl = useIntl()
	const isFormSectionExpanded = useUnit($isFormExpanded)
	const currentEventId = useUnit($currentEventId)

	const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		setTimeout(() => {
			fetchSortedEventsFx()
			currentEventId && fetchvCardFx(currentEventId)
			event.detail.complete()
		}, 1000)
	}
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>
						{intl.formatMessage({
							id: "editor-page-title",
							defaultMessage: "Quantori QR Code",
						})}
					</IonTitle>
					<IonButtons slot="end">
						<SignOutButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent>
				<IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
					<IonRefresherContent />
				</IonRefresher>
				<div className={classes.sections}>
					<div className={classes.section}>
						<EventsSection />
					</div>

					<div
						className={clsx(
							classes.section,
							isFormSectionExpanded && classes.sectionForm
						)}
					>
						<VcardSection />

						<div className={classes.expandButton}>
							<ExpandButton
								expanded={isFormSectionExpanded}
								onClick={() => isFormExpandedChanged(!isFormSectionExpanded)}
							/>
						</div>
					</div>
				</div>
			</IonContent>
		</IonPage>
	)
}
